import React from 'react'
import './about.css'

export default function Home() {
  return (
    <section className="about_page">
   <h1 className="about_text">About Us</h1>
   </section>
   
  )
}
